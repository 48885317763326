.poker-table-space {
  border-radius: 12px;
  position: relative;
  min-height: 550px;
  height: 550px;
  max-height: 550px;
  max-width: 800px;
}

.poker-table-space-actions {
  max-width: 800px;
}

.poker-table-pot {
  border: 2px solid #cccccc;
  position: absolute;
  transform: translate(-50%, 70%);
  top: 50%;
  left: 50%;
  border-radius: 25px;
  padding: 5px;
  background: #35554d;
  font-size: 12px;
  color: white;
}

.poker-table-results {
  position: absolute;
  top: 0%;
  left: 50%;
  font-weight: 600;
  border: 3px solid gold;
  padding: 0px 15px;
  border-radius: 20px;
  transform: translate(-50%);
}

.poker-table-pot.preflop {
  transform: translate(-50%, -50%);
}

.poker-table-cc-above-pot {
  position: absolute;
  line-height: 1;
  transform: translate(-50%, calc(-100% - 3px));
  top: 0%;
  left: 50%;
}

.poker-table-cc-below-pot {
  position: absolute;
  line-height: 1;
  transform: translate(-50%, calc(3px));
  top: 100%;
  left: 50%;
}

.poker-table {
  width: 80%;
  height: 60%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 150px;
  border: 10px solid brown;
  background: #35654d;
  padding: 10px;
}

.seat {
  width: 10%;
  height: 12.5%;
}

.seatinfo {
  padding: 5px;
  border-radius: 50%;
  font-size: 12px;
  background: #aaaaaa;
  border: 2px solid gray;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.seatinfo.state-IN_GAME {
  background: #eeeeee;
}

.seatinfo.state-ALL_IN {
  background: #eeeeee;
}

.seatinfo.shouldact.state-IN_GAME {
  border-color: gold;
  background: antiquewhite;
}

.seatinfo.acting.state-IN_GAME {
  border-color: gold;
  border-width: 4px;
}

.seatinfo.acting {
  border-color: gold;
  background: antiquewhite;
  opacity: 1;
}

.state-IN_GAME {
  /* background-color: gold; */
  /* border-radius: 50%; */
  /* border: 2px solid gold; */
}

.state-OUT_OF_GAME {
  opacity: 0.5;
}

.seat-1 {
  position: absolute;
  padding: 10px;
  left: 20%;
  top: 20%;
  transform: translate(50%, -50%);
}

.seat-2 {
  position: absolute;
  padding: 10px;
  left: 40%;
  top: 20%;
  transform: translate(50%, -50%);
}

.seat-3 {
  position: absolute;
  padding: 10px;
  left: 60%;
  top: 20%;
  transform: translate(50%, -50%);
}

.seat-4 {
  position: absolute;
  padding: 10px;
  left: 80%;
  top: 35%;
  transform: translate(50%, -50%);
}

.seat-5 {
  position: absolute;
  padding: 10px;
  left: 80%;
  top: 65%;
  transform: translate(50%, -50%);
}

.seat-6 {
  position: absolute;
  padding: 10px;
  left: 60%;
  top: 80%;
  transform: translate(50%, -50%);
}

.seat-7 {
  position: absolute;
  padding: 10px;
  left: 40%;
  top: 80%;
  transform: translate(50%, -50%);
}

.seat-8 {
  position: absolute;
  padding: 10px;
  left: 20%;
  top: 80%;
  transform: translate(50%, -50%);
}

.seat-9 {
  position: absolute;
  padding: 10px;
  left: 10%;
  top: 65%;
  transform: translate(-50%, -50%);
}

.seat-10 {
  position: absolute;
  padding: 10px;
  left: 10%;
  top: 35%;
  transform: translate(-50%, -50%);
}

.seat-bet {
  position: absolute;
  border: 2px dashed red;
  background-color: #f0e68c;
  padding: 5px;
  border-radius: 50%;
  white-space: nowrap;
}

.seat-1-bet {
  left: 50%;
  top: 100%;
  transform: translate(-100%);
}

.seat-2-bet {
  left: 55%;
  top: 100%;
  /* transform: translate(-50%); */
}

.seat-3-bet {
  left: 50%;
  top: 100%;
}

.seat-6-bet {
  left: 50%;
  top: -50%;
}

.seat-7-bet {
  top: -50%;
  left: 55%;
}

.seat-8-bet {
  top: -55%;
  left: 0%;
}

.seat-9-bet {
  top: 0%;
  left: 100%;
  transform: translate(0%, 50%);
}

.seat-10-bet {
  top: 100%;
  left: 100%;
}

.seat-4-bet {
  top: 0%;
  left: 0%;
  transform: translate(-100%);
}

.seat-5-bet {
  top: 100%;
  left: 0%;
  transform: translate(-100%, -100%);
}

.btn {
  font-size: 12px;
}

.btn:after {
  content: "BTN";
  font-weight: 600;
  color: red;
  padding: 3px;
  position: absolute;
  border: 1px #aaaaaa solid;
  border-radius: 50%;
  background-color: #ffffcc;
}

.seat-1-btn:after {
  left: 100%;
  top: 100%;
  transform: translate(10%, -100%);
}

.seat-2-btn:after {
  left: 0%;
  top: 100%;
  transform: translate(-90%, -100%);
}

.seat-3-btn:after {
  left: 0%;
  top: 100%;
  transform: translate(-90%, -100%);
}

.seat-10-btn:after {
  left: 100%;
  top: 50%;
  /* transform: translate(-50%, 50%); */
}

.seat-4-btn:after {
  left: 0%;
  top: 100%;
  transform: translate(-50%, 0%);
}

.seat-5-btn:after {
  left: 0%;
  top: 0%;
  transform: translate(-50%, -100%);
}

.seat-6-btn:after {
  left: 0%;
  top: 0%;
  transform: translate(-70%, -100%);
}

.seat-7-btn:after {
  left: 0%;
  top: 0%;
  transform: translate(-70%, -100%);
}

.seat-8-btn:after {
  left: 100%;
  top: 0%;
  /* transform: translate(-70%, -100%); */
}

.seat-9-btn:after {
  left: 100%;
  top: 100%;
  transform: translate(30%, -110%);
}

.hero .seatinfo {
  /* background: gold; */
  /* border-radius: 25%; */
}

.hero.icon {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid gold;
  border-radius: 50%;
  /* background-color: white;
  background: #35554d; */
  background: #35654d;
  background: red;
  width: 20px;
  height: 20px;
}

.seat-1-cards {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -60%);
}

.seat-2-cards {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -60%);
}

.seat-3-cards {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -60%);
}

.seat-6-cards {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -40%);
}

.seat-7-cards {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -40%);
}

.seat-8-cards {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -40%);
}

.seat-4-cards {
  position: absolute;
  top: 0%;
  left: 100%;
  transform: translate(-60%, -60%);
}

.seat-10-cards {
  position: absolute;
  top: 0%;
  left: 0%;
  transform: translate(-30%, -70%);
}

.seat-9-cards {
  position: absolute;
  top: 100%;
  left: 0%;
  transform: translate(-30%, -40%);
}

.seat-5-cards {
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-60%, -40%);
}

.gtaction {
  font-size: 10px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 3px;
  /* margin: 3px; */
  display: flex;
  align-items: center;
  height: 20px;
  width: 200px;
  font-weight: 600;
}

.gtaction.hero {
  border-style: dashed;
  border-width: 2px;
  border-color: black;
}

.gtaction-a.hero {
  border-style: dashed;
  border-width: 3px;
  border-color: #aaaaaa;
}

.gtstreet {
  font-size: 14px;
  font-weight: 600;
}

.gtaction-r {
  background-color: orange;
}

.gtaction-b {
  background-color: orange;
}

.gtaction-f {
  background-color: darkgray;
}

.gtaction-a {
  background-color: darkred;
  color: white;
}

.gtaction-c {
  background-color: seagreen;
  color: white;
}

.gtaction:hover {
  cursor: pointer;
  font-weight: 600;
}

.gtactive {
  /* font-size: 12px; */
  font-weight: 600;
}

/* .xseat {
  width: 10%;
  height: 12.5%;
} */

.seat-pointer {
  transform: scale(0.6, 0.6);
}

.diagonaltb {
  background: linear-gradient(
    to top right,
    transparent calc(50% - 2px),
    yellow,
    transparent calc(50% + 2px)
  );
}

.diagonalbt {
  background: linear-gradient(
    to bottom right,
    transparent calc(50% - 2px),
    yellow,
    transparent calc(50% + 2px)
  );
}

.seat-1-pointer {
  position: absolute;
  left: 30%;
  top: 25%;
  width: 20%;
  height: 25%;
}

.seat-7-pointer {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1px;
  height: 25%;
  border: 1px solid yellow;
}

.seat-3-pointer {
  position: absolute;
  left: 50%;
  top: 25%;
  width: 20%;
  height: 25%;
}

.seat-4-pointer {
  position: absolute;
  left: 50%;
  top: 37.5%;
  width: 40%;
  height: 12.5%;
}

.seat-5-pointer {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40%;
  height: 12.5%;
}

.seat-8-pointer {
  position: absolute;
  left: 30%;
  top: 50%;
  width: 20%;
  height: 25%;
}

.seat-2-pointer {
  position: absolute;
  left: 50%;
  top: 25%;
  width: 1px;
  height: 25%;
  border: 1px solid yellow;
  /* transform: translate(0, -100%); */
}

.seat-6-pointer {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 20%;
  height: 25%;
}

.seat-9-pointer {
  position: absolute;
  left: 10%;
  top: 50%;
  width: 40%;
  height: 12.5%;
}

.seat-10-pointer {
  position: absolute;
  left: 10%;
  top: 37.5%;
  width: 40%;
  height: 12.5%;
}

.seat-pointer-action {
  font-size: 16px;
  font-weight: 600;
  padding: 20px;
  border-radius: 50%;
  background-color: aquamarine;
  border: 1px solid red;
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  transform: translate(-50%, -50%);
}

.scripted-hand {
  font-size: 12px;
}

.defaultCard {
  font-size: 40px;
  line-height: 1;
}

.tableCard {
  font-size: 80px;
  border-radius: 3px;
  line-height: 1;
}

.streetCard {
  font-size: 60px;
  border-radius: 3px;
  line-height: 1;
  margin: 1px;
  background: none;
}

.poker-table-space .cardWrapper {
  border-radius: 3px;
  overflow-y: hidden;
  height: 60px;
}

.poker-table-space .streetCard {
  font-size: 60px;
  background-color: white;
  display: block;
  position: relative;
  top: -8px;
}

.poker-table-space .gameFinished.cardWrapper {
  overflow-y: hidden;
  height: 100px;
}

.poker-table-space .gameFinishedCard {
  font-size: 100px;
  background-color: white;
  border-radius: 3px;
  display: block;
  position: relative;
  top: -12px;
  line-height: 1;
}

.seat-range {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 150px;
  transform: translate(-50%, -50%);
}

.seat-8-range {
  position: absolute;
}

@media only screen and (max-width: 800px) {
  .poker-table-space .gameFinished.cardWrapper {
    overflow-y: hidden;
    height: 80px;
  }

  .poker-table-space .gameFinishedCard {
    font-size: 80px;
    background-color: white;
    border-radius: 3px;
    display: block;
    position: relative;
    top: -12px;
    line-height: 1;
  }

  .seatinfo {
    font-size: 8px;
  }

  .btn {
    font-size: 8px;
  }

  .poker-table-space {
    min-height: 350px;
    max-height: 350px;
    height: 350px;
    max-width: 600px;
  }

  .poker-table-space-actions {
    max-width: 600px;
  }

  .scripted-hand {
    font-size: 10px;
  }

  .hero.icon {
    width: 16px;
    height: 16px;
  }

  .seat-pointer-action {
    font-size: 12px;
    padding: 10px;
  }

  .tableCard {
    font-size: 40px;
  }

  .poker-table-pot {
    font-size: 10px;
  }

  .poker-table-pot svg {
    width: 16px;
  }
}

.diagonalrangetb {
  background: linear-gradient(
    to top right,
    transparent calc(50% - 2px),
    #eeeeee,
    transparent calc(50% + 2px)
  );
}

.diagonalrangebt {
  background: linear-gradient(
    to bottom right,
    transparent calc(50% - 2px),
    #eeeeee,
    transparent calc(50% + 2px)
  );
}

.seat-range-pointer {
  transform: scale(0.8, 0.8);
}

.seat-range-2-pointer {
  position: absolute;
  left: 50%;
  top: 25%;
  width: 1px;
  height: 25%;
  border: 1px solid #eeeeee;
}

.seat-range-7-pointer {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1px;
  height: 25%;
  border: 1px solid #eeeeee;
}

.seat-range-1-pointer {
  position: absolute;
  left: 30%;
  top: 25%;
  width: 20%;
  height: 25%;
}

.seat-range-3-pointer {
  position: absolute;
  left: 50%;
  top: 25%;
  width: 20%;
  height: 25%;
}

.seat-range-4-pointer {
  position: absolute;
  left: 50%;
  top: 37.5%;
  width: 40%;
  height: 12.5%;
}

.seat-range-5-pointer {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40%;
  height: 12.5%;
}

.seat-range-8-pointer {
  position: absolute;
  left: 30%;
  top: 50%;
  width: 20%;
  height: 25%;
}

.seat-range-6-pointer {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 20%;
  height: 25%;
}

.seat-range-9-pointer {
  position: absolute;
  left: 10%;
  top: 50%;
  width: 40%;
  height: 12.5%;
}

.seat-range-10-pointer {
  position: absolute;
  left: 10%;
  top: 37.5%;
  width: 40%;
  height: 12.5%;
}

.minified-view-btn {
  position: absolute;
  left: 100%;
  margin-top: 1rem;
  margin-left: -1rem;
  transform: translate(-100%);
  cursor: pointer;
}

.winner {
  border: 4px double brown;
  background: gold;
  color: indigo;
  transition: transform 0.5s linear;
  transform: scale(1.3);
}

.winner::after {
  content: "Winner!";
}

.candidate {
  border: 4px double brown;
  background: bisque;
  color: indigo;
  transition: transform 0.5s linear;
  transform: scale(1.2);
}

.seat-nick {
  color: black;
  position: absolute;
  font-weight: 500;
  font-size: 12px;
  left: 0;
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
}

.seat-1-nick {
  top: 40%;
  transform: translate(calc(-100% - 5px), -100%);
}

.seat-2-nick {
  top: 40%;
  transform: translate(calc(-100% - 5px), -100%);
}

.seat-3-nick {
  top: 40%;
  transform: translate(calc(-100% - 5px), -100%);
}

.seat-4-nick {
  top: 100%;
  left: 50%;
}

.seat-5-nick {
  top: 0%;
  left: 50%;
  transform: translate(20%, -100%);
}

.seat-6-nick {
  top: 55%;
  transform: translate(calc(-100% - 5px));
}

.seat-7-nick {
  top: 55%;
  transform: translate(calc(-100% - 5px));
}

.seat-8-nick {
  top: 55%;
  transform: translate(calc(-100% - 5px));
}

.seat-9-nick {
  top: 0%;
  left: 50%;
  transform: translate(-100%, -120%);
}

.seat-10-nick {
  top: 100%;
  left: 50%;
  transform: translate(-100%, 10%);
}

.startingpot {
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dddddd;
}

.endingpot {
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  border-top: 1px solid #dddddd;
}

.potsymbol {
  border: 2px dotted red;
  background-color: #f0e68c;
  border-radius: 50%;
  min-width: 15px;
  min-height: 15px;
  text-align: center;
  margin-left: 5px;
  display: inline-block;
}

.winner-nick {
  font-weight: bold;
}

.winner-amount {
  font-weight: bold;
}

.winner-desc {
  font-style: italic;
}

.cardTooltip {
  display: none;
}

.cardToggle:hover .cardTooltip {
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  white-space: nowrap;
  text-transform: none;

  color: coral;
  border: 1px ridge coral;
  padding: 2px 3px 2px 3px;
  margin-top: 3px;
  margin-left: 3px;
  box-shadow: 2px 3px 3px gray;
  font-size: 12px;
}

.suit-h {
  color: red;
}

.suit-d {
  color: blue;
}

.suit-c {
  color: green;
}

.suit-s {
  color: black;
}

.cardToggle.suit-h {
  color: red !important;
}

.cardToggle.suit-d {
  color: blue !important;
}

.cardToggle.suit-c {
  color: green !important;
}

.cardToggle.suit-s {
  color: black !important;
}

.suit-h.Mui-disabled {
  color: rgba(16, 16, 16, 0.3) !important;
}

.suit-d.Mui-disabled {
  color: rgba(16, 16, 16, 0.3) !important;
}

.suit-c.Mui-disabled {
  color: rgba(16, 16, 16, 0.3) !important;
}

.suit-s.Mui-disabled {
  color: rgba(16, 16, 16, 0.3) !important;
}

.currentActionRange > .MuiBox-root {
  border: 1px solid red;
  background-color: #fff0cc;
}

.gtaction.active {
  font-size: 12px;
  border-color: red;
  border-width: 2px;
  border-style: solid;
}

.cardSelectorCell {
  border-right: 1px solid hsl(240, 3%, 80%);
}

.cardSelectorCell:hover {
  background-color: hsl(240, 3%, 94%);
}

.cardSelectorCell.disabled {
  cursor: default;
  color: hsl(240, 3%, 80%);
}

.cardSelectorRow .cardSelectorCell:nth-child(1) {
  border-left: 1px solid hsl(240, 3%, 80%);
}

.cardSelectorRow {
  border-bottom: 1px solid hsl(240, 3%, 80%);
}

.cardSelectorRow:nth-of-type(1) {
  border-top: 1px solid hsl(240, 3%, 80%);
}

.cardSelectorCell.selected {
  background-color: hsl(240, 3%, 70%);
}

.hh-info {
  font-size: 12px;
  font-weight: bold;
  border: 1px solid lightgrey;
  padding: 4px;
}
