.range-row {
  min-height: 7%;
  height: 7%;
  align-items: center;
}

.range-cell {
  border: 1px solid black;
  text-align: center;
  display: inline-block;
  color: white;
  width: 7.68%;
  min-height: 20px;
}

.range-cell-mini {
  text-align: center;
  display: flex;
  flex-direction: column;
  color: white;
  flex-grow: 1;
  width: 0px;

  min-height: 10px;
  min-width: 10px;
}

.outofrange {
  animation: blinkingBackground 2s infinite;
}

@keyframes blinkingBackground {
  0% {
    background-color: #10c018;
  }
  25% {
    background-color: #1056c0;
  }
  50% {
    background-color: #ef0a1a;
  }
  75% {
    background-color: #254878;
  }
  100% {
    background-color: #04a1d5;
  }
}

.missed_range {
  opacity: 0.3;
}

.missed_label {
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 1;
  color: red;
  font-size: 24px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.rangedata {
  position: relative;
}
