.tf-tree.tf-custom .op-b.tf-nc:before {
  border-left-color: brown;
  border-left-width: 1px;
}

.tf-tree.tf-custom .op-r.tf-nc:before {
  border-left-color: brown;
  /* border-left-width: 1px; */
}

.tf-tree.tf-custom .op-c.tf-nc:before {
  border-left-color: green;
  /* border-left-width: 1px; */
}

.tf-tree.tf-custom .op-f.tf-nc:before {
  border-left-color: gray;
  /* border-left-width: 1px; */
}

.tf-tree.tf-custom .op-x.tf-nc:before {
  border-left-color: green;
  /* border-left-width: 1px; */
}

.tf-tree.tf-custom li li:before {
  border-top-color: dodgerblue;
  /* border-top-width: 1px; */
}

.tf-horizontal-tree.tf-custom .op-b.tf-nc:before {
  /* border-top-width: 0.5em; */
  /* top: calc(50% - 0.25em); */
  /* max-height: calc(50% + 0.0625em); */
  border-top-color: brown;
}

/* .w1.op-b:before {
  border-top-width: 0.5em;
  top: calc(50% - 0.25em);
}

.w2.op-b:before {
  border-top-width: 0.25em;
  top: calc(50% - 0.125em);
} */

.tf-horizontal-tree.tf-custom .op-r.tf-nc:before {
  border-top-color: brown;
  /* border-top-width: 1px; */
}

.tf-horizontal-tree.tf-custom .op-c.tf-nc:before {
  border-top-color: green;
  /* border-top-width: 1px; */
}

.tf-horizontal-tree.tf-custom .op-f.tf-nc:before {
  border-top-color: gray;
  /* border-top-width: 1px; */
}

.tf-horizontal-tree.tf-custom .op-x.tf-nc:before {
  border-top-color: green;
  /* border-top-width: 1px; */
}

.tf-horizontal-tree.tf-custom li li:before {
  /* border-left-color: dodgerblue; */
  /* border-left-width: 1px; */
}

.tf-custom .tf-nc {
  border-radius: 0.5em;
}

.tf-nc .link {
  border-radius: 0.5em;
  padding: 0.2em 0.5em;
  border: 0.0625em solid #000;
}

.tf-nc .node {
  border-radius: 0.5em;
  padding: 0.3em 0.5em;
  font-size: 0.75em;
  min-width: 3em;
}

.tf-nc .node.loading {
  min-width: 6em;
  min-height: 3em;
}

.zoom-small .tf-custom .tf-nc .node {
  font-size: 12px;
}

.zoom-small .tf-custom .tf-nc .link {
  font-size: 10px;
}

.zoom-small .tf-custom .tf-nc .node .player-spr {
  display: none;
}

.zoom-small .tf-custom .tf-nc .node .player-position {
  display: none;
}

.zoom-small .tf-custom .tf-nc .node .street-estack {
  display: none;
}

.zoom-smallest .tf-custom .tf-nc .link {
  font-size: 8px;
}

.zoom-smallest .tf-custom .tf-nc .node {
  font-size: 10px;
}

.zoom-smallest .tf-custom .tf-nc .node .player-spr {
  display: none;
}

.zoom-smallest .tf-custom .tf-nc .node .player-position {
  display: none;
}

.zoom-smallest .tf-custom .tf-nc .node .street-estack {
  display: none;
}

.node {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  border: 0.0625em solid #000;
}

.error-node {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* cursor: pointer; */
  background-color: "pink";
  border: 0.0625em solid red;
  border-radius: 0.5em;
}

.tree-wrapper {
  flex-basis: 50%;
  user-select: none;
  /* font-size: 12px; */
  white-space: nowrap;
  /* max-width: 1200px; */
  max-height: 800px;
  /* min-height: 300px; */
  /*   background: yellow; */
  border: 1px solid #999999;
  /* background: #cccccc; */
  /* cursor: grab; */
  /* overflow: hidden; */
  overflow: scroll;
  /*   position: relative; */
  /* font-size: 1em; */
}

.tf-draggable {
  /*   position: absolute; */
  /*   cursor: move; */
  user-select: none;
  /*   transform: scale(0.5);
      transform-origin: 0 0; */
}

.tree-wrapper li {
  flex-wrap: nowrap;
}

.tree-wrapper ul {
  flex-wrap: nowrap;
}

.pftreeCard {
  font-size: 6em;
  line-height: 1;
}

.card-choosen {
  background-color: yellow;
}

.street-switch {
  background-color: antiquewhite;
}

.player-action {
  background-color: aliceblue;
}

.node-loading {
  background: antiquewhite;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 8px;
}

.choosen-op {
  /* border: 1px solid red; */
  /* margin: -1px !important; */
}

.non-choosen-op {
  opacity: 0.5;
}
