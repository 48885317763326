.pfrange-cell {
  border: 1px solid black;
  display: flex;
  width: 7.69%;
  height: 35px;
  font-size: 10px;
  flex-direction: column;
  padding-left: 2px;
  line-height: 1;
}

.pfrange-row {
  display: flex;
}

.pfrange-cell.pfrange-selected {
  border-color: red;
  border-width: 2px;
}

.pfrange {
  width: 500px;
}

.pfstrategy {
  width: 600px;
}

.pfstrategy-cell {
  border: 1px solid gray;
  display: flex;
  width: 7.69%;
  height: 52px;
  font-size: 10px;
  flex-direction: column;
  line-height: 1;
  padding-left: 2px;
  overflow: hidden;
}

.missed_pflabel {
  position: absolute;
  left: 50%;
  top: 0%;
  opacity: 1;
  color: red;
  font-size: 24px;
  font-weight: bold;
  transform: translate(-50%);
  white-space: nowrap;
}

.pfCard {
  font-size: 60px;
  border-radius: 3px;
  line-height: 1;
  margin: 1px;
  background: none;
}

.pfCard.suit-h {
  color: rgb(127, 27, 15);
}

.pfstrategy-cell.pfrange-selected {
  border-color: red;
  border-width: 2px;
}

.range-switcher {
  position: absolute;
  left: 0%;
  top: 0%;
}

.currentTreeAction {
  border: 1px solid red;
}

.actorPFRange {
  color: red;
  font-weight: bold;
}

.activePlayer {
  margin-top: -3px;
  border: 3px solid black;
  background-color: #fff0dd;
}

.pfrange_nick {
  font-style: italic;
}

.e-grid .e-gridheader .e-columnheader {
  display: none;
}
