.choosen-action {
  background: #aaaaaa;
}

.current-action {
  border: 2px solid green;
  font-weight: 600;
}

.current-action .position {
  color: green;
}

.range_pairx {
  transform: scale(0.8);
  transform-origin: 0 0;
  margin: 0 calc(-2 * 450px * (1 - 0.8)) calc(-520px * (1 - 0.8)) 0;
}
